import React, { Component } from 'react';
import articleMapDataToComponents, {
  articleFaqMap
} from '../components/article/articleMapDataToComponents';

import ArticleIntro from '../components/article/articleIntro';
import ContentBackground from '../components/ui/contentBackground';
import FaqList from '../components/article/faqList';
import HeadTags from '../components/global/headTags';
import ImpactedRegions from '../components/article/impactedRegions';
import Reveal from '../components/ui/reveal';
import SocialShare from '../components/global/socialShare';
import VideoEmbed from '../components/basic/videoEmbed';
import { graphql } from 'gatsby';
import translations from '../utils/translations';
import DefaultLayout from '../layouts/default-layout';
import * as style from './article.module.scss';
import Hero from '../components/article/hero';
import LatestStories from '../components/latestStories';
import Newsletter from '../components/newsletter';
import FiledUnder from '../components/article/filedUnder';
import AuthorBio from '../components/authorBio';
import ArticleList from '../components/article/articleList';
import { getLanguage } from '../utils/language';
import ArticleThemeContext from '../context/articleThemeContext';
import HeaderVideo from '../components/article/headerVideo';

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      lang: 'en',
    };
    this.articleType = null;
    if (props.handlePageDataChanged && props.data.itemsJson.article_type) {
      props.handlePageDataChanged({ articleType: props.data.itemsJson.article_type });
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const newArticleType = nextProps.data.itemsJson.article_type;
    if (nextProps.handlePageDataChanged && newArticleType !== this.articleType) {
      this.articleType = newArticleType;
      nextProps.handlePageDataChanged({ articleType: newArticleType });
    }
  }
  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const lang = await getLanguage();
    this.setState({ lang });
    translations.setLanguageSource(lang, this.props.data.translations);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  render() {
    const {
      title,
      hide_date,
      created_date,
      archive,
      authors,
      meta_description,
      related_challenge,
      featured_image,
      featured_video,
      video,
      youtube_video,
      related_impacted_regions,
      make_this_article_faq,
      prevent_indexing
    } = this.props.data.itemsJson;
    const {
      popular_stories
    } = this.props.data;

    const relatedReading = this.props.data.itemsJson.paragraphs.find(p => p.type === 'article_list');
    
    const paragraphs = this.props.data.itemsJson.paragraphs
      .filter(p => p.type !== 'article_list' && p.type !== 'partnership');
    const stories = this.props.data.megaMenu.edges.find(e => e.node.lang === this.state.lang).node.latest_stories;
	
	const latestArticleStories = this.props.data.megaMenu.edges.find(e => e.node.lang === this.state.lang).node.latest_article_stories;
	
    return (
      <DefaultLayout {...this.props} excludeParentFunctions>
        <ArticleThemeContext.Provider value="light">
          <div
            // style={this.props.transition && this.props.transition.style}
            // for some reason, fading out the whole article resulted in a long page getting
            // cut off. Some rendering issue. So instead we fade in a dark overlay on top of the content.
            className={`
              ${this.props.transition && this.props.transition.status === 'exiting'
                ? 'article-exiting'
                : ''}
            `}
          >
            <HeadTags
              title={title}
              description={meta_description}
              image={featured_image ? featured_image.image.sizes.hero_md : null}
              imageTwitter={
                featured_image ? featured_image.image.sizes.hero_sm : null
              }
              preventIndexing={prevent_indexing}
              location={this.props.location}
              isArticle
            />
            <div className={style.wrap}>
              <div className={style.spacer}/>
              {!youtube_video.url && <Hero src={featured_image ? featured_image.image.sizes.hero_lg : ''}
                title={title}
                subtitle={meta_description}
                authors={authors}
                createdDate={created_date}
                credit={featured_image.image.credit_plain}
                hideDate={hide_date}
              />
              }
              {youtube_video.url && <HeaderVideo
                url={youtube_video.url}
                credit={youtube_video.credit}
                title={title}
                subtitle={meta_description}
                authors={authors}
                createdDate={created_date}
                hideDate={hide_date}
                />}
              <div className={style.container}>
                <div className={style.threeColumn}>
                  <div>
                    <LatestStories stories={stories} label={translations.t('Latest Stories')} />
                  </div>
                  <div>
                    <Newsletter en={this.props.data.newsletter_component_en} es={this.props.data.newsletter_component_es} />
                    <div className={`${style.paragraphs} ${style.light}`}>
                      {make_this_article_faq === '1' && <FaqList paragraphs={paragraphs} />}

                      {make_this_article_faq === '1'
                        ? articleFaqMap(paragraphs, this.state.width, make_this_article_faq === '1')
                        : articleMapDataToComponents(
                          paragraphs,
                          popular_stories,
                          {
                            textClass: style.normalTextBlock,
                            theme: 'light'
                          },
                          make_this_article_faq === '1'
                        )}

                      {related_impacted_regions && (
                        <Reveal>
                          <ImpactedRegions
                            calloutHeading={translations.t('Featured Regions')}
                            climateTrendPath={this.props.data.climateTrends.path}
                            regions={related_impacted_regions.map(item => ({
                              title: item.title,
                              url: item.path,
                              regionId: item.region_id,
                            }))}
                          />
                        </Reveal>
                      )}
                    </div>
                    <div>
                      <div className={style.inlineSocialShareMobile}>
                        <SocialShare horizontal static color="orange" title={title} />
                      </div>
                      <div className={style.articleFooter}>
                        <div>
                          <FiledUnder lang={this.state.lang} label={translations.t('Filed Under')} categories={this.props.data.itemsJson.categories} />
                        </div>
                        <div>
                          <SocialShare horizontal static color="orange" title={title} />
                        </div>
                      </div>
                      <AuthorBio authors={this.props.data.itemsJson.authors} />
                    </div>
                  </div>
                  <div>&nbsp;</div>
                </div>
              </div>
              
              {/*
              {relatedReading
                && <div className={style.relatedReading}>
                  <ArticleList theme="off-white" articles={relatedReading.items} label={translations.t("Related Reading")} />
                </div>
              }
              */}

              {latestArticleStories
                && <div className={style.relatedReading}>
                  <ArticleList theme="off-white"
                    articles={latestArticleStories}
                    label={translations.t("Latest Stories")}
                  />
                </div>
              }
              
            </div>
            {/* {!featured_video &&
            <ContentBackground
              src={featured_image ? featured_image.image.sizes.hero_lg : ''}
              videoSrc={video ? video.url : ''}
              videoOpacity={0.4}
            />
          }
          {featured_video &&
            <ArticleIntro
              title={title}
              created_date={created_date}
              label={related_challenge.title}
              archive={archive}
              authors={authors}
              isIE={this.props.isIE}
              backButton={!!related_impacted_regions}
              history={this.props.history}
              small
            />
          }
          {!featured_video &&
            <ArticleIntro
              title={title}
              created_date={created_date}
              label={related_challenge.title}
              archive={archive}
              authors={authors}
              isIE={this.props.isIE}
              backButton={!!related_impacted_regions}
              history={this.props.history}
            />
          }
          {featured_video &&
            <div className="text-block">
              <VideoEmbed
                heading={''}
                imgSrc={featured_video.image ? featured_video.image.sizes.standard_md : ''}
                videoUrl={featured_video.url}
                videoId={featured_video.id}
                isFeatured
                small
              />
            </div>
          }
          */}
            <SocialShare labelStyle={this.state.lang === 'es' ? { bottom: '120px' } : {}} color="orange" title={title} />
          </div>
        </ArticleThemeContext.Provider>
      </DefaultLayout>
    );
  }
}

export default Article;

// eslint-disable-next-line
export const articlePageQuery = graphql`
  query ArticlePageContentByPathNew(
    $path: String!,
    $climateTrendId: String!,
    $popularStoriesId: String!,
    $isEs: Boolean!,
    $idRegex: String!,
    $blog: String!,
    $translations: String!) {
   itemsJson(path: { eq: $path }) {
      title
      hide_date
			created_date
      short_description
      meta_description
      article_type
      related_challenge {
        title
      }
      archive {
        title
        path
      }
      authors {
        id
        name
        description
        description_plain
        social_media_link {
          uri
          title
        }
        linkedin_link {
          uri
          title
        }
        bio_url {
          uri
          title
        }
        bio_image {
          url
          alt
        }
      }
      youtube_video {
        credit
        url
      }
      make_this_article_faq
      featured_image {
        image {
          sizes {
            hero_lg
            hero_md
            hero_sm
            original
          }
          credit_plain
        }
      }
      video {
        url
      }
      related_impacted_regions {
        content_type
        path
        title
        region_id
      }
      featured_video {
        id
        url
        caption
        image {
          sizes {
            original
            standard_md
            standard_sm
          }
        }
      }
      categories {
        name
        es_name
        link
      }
      prevent_indexing
      ...queryParagraphFields
    }
    climateTrends: itemsJson(jsonId: { eq: $climateTrendId }) {
      path
    }
    popular_stories: itemsJson(jsonId: { eq: $popularStoriesId }) {
      articles {
        id
        title
        path
        archive {
          title
          path
        }
        authors {
          name
        }
      }
    }

  ...headerFields @skip(if:$isEs)
	...footerFields @skip(if:$isEs)
  ...headerFieldsEs @include(if:$isEs)
  ...footerFieldsEs @include(if:$isEs)
	challenges: allItemsJson(
		filter: {content_type: {regex: "/timeline|article|challenge/"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			related_challenge {
				title
				path
			}
			core_curriculum {
				title
				path
			}
			}
		}
		}
		pages: allItemsJson(
		filter: {content_type: {eq: "page"}, jsonId: {regex: $idRegex}}
		) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		blog: allItemsJson(filter: {jsonId: {eq: $blog}}) {
		edges {
			node {
			title
			content_type
			path
			}
		}
		}
		translations: allItemsJson(filter: {jsonId: {eq: $translations}}) {
		edges {
			node {
			translations {
				id
				key
				value
			}
			}
		}
	}
  newsletter_component_en: allItemsJson(filter: {jsonId: {eq: "en-11"}}) {
    edges {
      node {
        newsletter_intro
        newsletter_complete
        newsletter_action
      }
    }
  }
  newsletter_component_es: allItemsJson(filter: {jsonId: {eq: "es-11"}}) {
    edges {
      node {
        newsletter_intro
        newsletter_complete
        newsletter_action
      }
    }
  }
  megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
            projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			latest_article_stories {
				id
				title
				path
				image {
					sizes {
						hero_lg
						hero_md
						hero_sm
						original
						near_square_md
					}
				}
				archive {
					id
					title
					path
				}
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
}
`;
