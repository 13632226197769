import React from 'react';
import LogoSvg from '../global/logoSvg';
import * as style from './hero.module.scss';
import Link from 'gatsby-link';
import translations from '../../utils/translations';

const Hero = (props) => {
	const {
		title,
		subtitle,
		authors,
		createdDate,
		src,
		credit,
		hideDate,
	} = props;

	const imgStyle = {
		backgroundImage: `url(${src})`,
	};

	const _authors = authors && authors.length > 0 ? authors.map((a, index) => {
		return <React.Fragment key={index}>
			<span>{a.name}</span>{index < authors.length - 1 && <span>&amp;</span>}
		</React.Fragment>;
	}) : [];

	return (
		<>
			<div className={style.container}>
				<div className={style.left}>
					<div className={style.logo}>
						<Link to="/">
							<LogoSvg />
						</Link>
					</div>
					<div className={style.meta}>
						<h1>{title}</h1>
						<p>{subtitle.substring(0, 256)}</p>
						<div className={style.attribution}>
							<div className={style.authors}>{translations.t('By')} {_authors}</div>
							{hideDate === false
								&& <span className={style.createdDate}>
									{new Intl.DateTimeFormat(
										'en-US',
										{
											year: 'numeric',
											month: 'long',
											day: 'numeric'
										}
									).format((createdDate * 1000))}
								</span>
							}

						</div>
					</div>
				</div>
				<div className={style.right}>
					<div style={imgStyle} className={style.imageDesktop}></div>
					<img src={src} className={style.imageMobile} />
				</div>
			</div>
			{credit
				&& <div className={style.credit}>
					{credit}
				</div>
			}
		</>
	)
}

export default Hero;