import React from 'react';
import * as style from './headerVideo.module.scss';
import LogoSvg from '../global/logoSvg';
import Link from 'gatsby-link';
import translations from '../../utils/translations';

export default function HeaderVideo(props) {
	// URL Link		https://www.youtube.com/watch?v=cfJRpeCDoKo
	// Short Link	https://youtu.be/cfJRpeCDoKo
	// Embed Link	https://www.youtube.com/embed/cfJRpeCDoKo
	// The youtube iframe expects the url to be in the embed link format.

	const videoUrl = (props.url.replace(/watch\?v=(.+?)/, 'embed/$1')
		.replace(/(?:https|http):\/\/(.*?)youtu\.be/, 'https://www.youtube.com/embed'))+"?autoplay=0&controls=0&disablekb=1&fs=0&iv_load_policy=3&modestbranding=1&rel=0";
	const {
		authors,
		title,
		subtitle,
		hideDate,
		createdDate,
		credit
	} = props;

	const _authors = authors && authors.length > 0 ? authors.map((a, index) => {
		return <React.Fragment key={index}>
			<span>{a.name}</span>{index < authors.length - 1 && <span>&amp;</span>}
		</React.Fragment>;
	}) : [];

	return <div className={style.wrap}>
		<div className={style.logo}>
			<Link to="/">
				<LogoSvg />
			</Link>
		</div>
		<div className={style.container}>
			<div className={style.mobile}>
				<div className={style.contentType}>video</div>
				<h1>{title}</h1>
				<div className={style.attribution}>
					<div className={style.authors}>{translations.t('By')} {_authors}</div>
					{hideDate === false
						&& <span className={style.createdDate}>
							{new Intl.DateTimeFormat(
								'en-US',
								{
									year: 'numeric',
									month: 'long',
									day: 'numeric'
								}
							).format((createdDate * 1000))}
						</span>
					}
				</div>
			</div>
			<div className={style.video}>
				<iframe
					src={videoUrl}
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
					data-videourl={videoUrl}
				></iframe>
				{credit && <div className={`${style.credit} ${style.mobile}`}>{credit}</div>}
			</div>
		</div>

	</div>;
}