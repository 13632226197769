// extracted by mini-css-extract-plugin
export var wrap = "headerVideo-module--wrap--fAIV-";
export var mobile = "headerVideo-module--mobile--GUmbA";
export var container = "headerVideo-module--container--RBNoI";
export var video = "headerVideo-module--video--b2iip";
export var credit = "headerVideo-module--credit--btg2R";
export var contentType = "headerVideo-module--contentType--jkHtq";
export var authors = "headerVideo-module--authors--xXKl4";
export var attribution = "headerVideo-module--attribution--DpAjH";
export var logo = "headerVideo-module--logo--nhIgn";
export var slideUp = "headerVideo-module--slideUp--28nSm";
export var slideUpLarge = "headerVideo-module--slideUpLarge--PjqWR";