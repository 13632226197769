import React from 'react';
import * as style from './index.module.scss';

class LatestStories extends React.Component {
	render() {
		const stories = this.props.stories || [];
		const storyItems = stories.map((item, index) => {
			return <div className={style.storyItem} key={index}>
				<a href={item.uri}>{item.title}</a>
			</div>;
		});

		return (
			<div className={style.container}>
				<h4>{this.props.label}</h4>
				<div className={style.storyItems}>
					{storyItems}
				</div>
			</div>
		)
	}
}

export default LatestStories;