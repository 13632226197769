// extracted by mini-css-extract-plugin
export var container = "hero-module--container--ZxqpJ";
export var logo = "hero-module--logo--XRVD+";
export var meta = "hero-module--meta--J7rUI";
export var left = "hero-module--left--PWK+u";
export var authors = "hero-module--authors--Wtqyh";
export var createdDate = "hero-module--createdDate--VTxUR";
export var attribution = "hero-module--attribution--4zurt";
export var right = "hero-module--right--eVBbQ";
export var imageDesktop = "hero-module--imageDesktop--jTplG";
export var imageMobile = "hero-module--imageMobile--G+L6b";
export var credit = "hero-module--credit--HboX8";
export var slideUp = "hero-module--slideUp--Fn4Le";
export var slideUpLarge = "hero-module--slideUpLarge--gGHRO";