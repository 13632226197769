import React from 'react';
import * as style from './newsletter.module.scss';
import translations from '../../utils/translations';
import RoundedButton from '../buttons/roundedButton';
import { getLanguage } from '../../utils/language';
import $ from 'jquery';

const UNKNOWN_ERROR = 'An unknown error has occurred.';

class Newsletter extends React.Component {
	constructor(props) {
		super(props);

		this.onSubscribe = this.onSubscribe.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.setData = this.setData.bind(this);

		this.formRef = React.createRef();
	}

	signUpText = '';
	completeText = '';
	state = {
		open: false,
		completeText: '',
		step: 0,
		emailAddress: '',
		firstName: '',
		lang: 'en'
	};

	async componentDidMount() {
		const lang = await getLanguage();
		this.setState({ lang });
	}

	setData(evt) {
		this.setState({ [evt.target.name]: evt.target.value });
	}

	onSubscribe = (evt) => {
		evt.preventDefault();
		this.setState({ step: 1 });
	}

	onSubmit = (evt) => {
		if (!this.formRef.current.checkValidity()) {
			this.formRef.current.reportValidity();
			return false;
		}

		evt.preventDefault();
		const self = this;

		const data = {
			'EMAIL': this.state.emailAddress,
			'FNAME': this.state.firstName,
			'b_f638b14bd99d4a67f72a673b2_b758bb0b52': ''
		};
		$.ajax({
			url: 'https://outrider.us12.list-manage.com/subscribe/post-json?u=f638b14bd99d4a67f72a673b2&amp;id=b758bb0b52&c=?',
			dataType: 'jsonp',
			data: data,
			cache: false,
			success: function (data) {
				if (data.result && data.result === 'error') {
					self.setState({apiError: data.msg});
				} else if (data.result && data.result === 'success') {
					self.setState({apiError: '', step: 2});
				} else {
					self.setState({apiError: UNKNOWN_ERROR});
				}
			}.bind(this),
			error: function (xhr, status, err) {
				self.setState({apiError: err.toString()});
			}.bind(this)
		});
	}

	render() {
		const {
			newsletter_intro,
			newsletter_complete,
			newsletter_action
		} = this.state.lang === 'en' ? this.props.en.edges[0].node : this.props.es.edges[0].node;

		return (
			<div className={style.container}>
				{this.state.step < 2
					&& <div className={style.initialStep}>
						<span className={style.text}>
							{newsletter_intro} <a href="" onClick={this.onSubscribe}>{newsletter_action}</a>
						</span>
						{this.state.step === 1
							&& <form onSubmit={this.onSubmit} action="" ref={this.formRef}>
								<div className={style.form}>
									<div>
										<label htmlFor="firstName">First Name</label>
										<input
											required
											onChange={this.setData}
											type="text"
											id='firstName'
											name='firstName' />
									</div>
									<div>
										<label htmlFor="emailAddress">Email</label>
										<input
											required
											onChange={this.setData}
											type="email"
											id='emailAddress'
											name='emailAddress' />
									</div>
								</div>
								<div className={style.errorText}>{this.state.apiError}</div>
								<div className={style.submitBtnContainer}>
									<RoundedButton text={translations.t('Join')} color="orange" onClick={this.onSubmit} type="submit" />
								</div>
							</form>
						}
					</div>
				}

				{this.state.step === 2
					&& <div className={style.completeStep}>
						{newsletter_complete}
					</div>
				}
			</div>
		)
	}
};

export default Newsletter;